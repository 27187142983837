import { useEffect } from "react";

export const InstagramVideo = ({ link }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//www.instagram.com/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <blockquote
      className="instagram-media"
      data-instgrm-permalink={link}
      data-instgrm-version="14"
      style={{
        background: "#FFF",
        border: 0,
        borderRadius: 3,
        boxShadow: "0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)",
        marginTop: "10px",
        width: "100%",
        // height: "100%",
        // minWidth: 326,
        padding: 0,
        // width: "99.375%",
        // width: "-webkit-calc(100% - 2px)",
        // width: "calc(100% - 2px)",
      }}
    ></blockquote>
  );
};

export default InstagramVideo;
