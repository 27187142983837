import { useEffect, useRef, useState } from "react";

import "./MainCss.css";
export const MainPage = ({ scrollIndex }) => {
  const videosRef = useRef([]);
  //   let scrollIndex = 0;
  const scrollInterval = 4000; // 3 seconds

  useEffect(() => {
    const handleScrollSnap = () => {
      videosRef.current.forEach((video, index) => {
        const rect = video.getBoundingClientRect();
        const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;

        if (isVisible) {
          video.play();
        } else {
          video.pause();
        }
      });
    };

    window.addEventListener("scroll", handleScrollSnap);
    const scrollTimer = setInterval(() => {
      if (scrollIndex >= videosRef.current.length) {
        scrollIndex = 0; // Reset scroll index if it exceeds the number of videos
      }
      videosRef.current[scrollIndex].scrollIntoView({ behavior: "smooth" });
      scrollIndex++;
    }, scrollInterval);
    return () => {
      window.removeEventListener("scroll", handleScrollSnap);
      clearInterval(scrollTimer); // Clear the interval when the component unmounts
    };
  }, []);
  const videoItems = [
    "https://d1i6jxikpbnx7m.cloudfront.net/you'd_still_look_fuxxxx_good_to_me.webm",
    "https://d1i6jxikpbnx7m.cloudfront.net/live_clip1.webm",
    "https://d1i6jxikpbnx7m.cloudfront.net/live_clip2.webm",
    "https://d1i6jxikpbnx7m.cloudfront.net/live_clip3.webm",
    "https://d1i6jxikpbnx7m.cloudfront.net/live_clip4.webm",
    "https://d1i6jxikpbnx7m.cloudfront.net/live_clip5.webm",
  ];
  const [VideoLoadState, setVideoLoadState] = useState(false);
  const videoLoading = () => {
    setVideoLoadState(true);
  };
  return (
    <div className="Main_Page">
      <main className="Scroll-Container">
        {videoItems.map((item, index) => {
          return (
            <div className="Video-Item">
              {VideoLoadState ? null : (
                <span className="loader loadingPosition"></span>
              )}
              <video
                style={{ height: "100vh" }}
                ref={(el) => (videosRef.current[index] = el)}
                muted
                loop
                autoPlay
                onLoadedData={() => {
                  videoLoading();
                }}
              >
                <source src={item} type="video/mp4" />
              </video>
            </div>
          );
        })}
      </main>
    </div>
  );
};

export default MainPage;
