import { useRef, useState } from "react";
import "./ContactCss.css";
import emailjs from "@emailjs/browser";

export const ContactPage = () => {
  const contactItem = useRef();
  const [loadingModal, setLoadingModal] = useState(false);
  const [submitModal, setSubmitModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const Submit = (e) => {
    e.preventDefault();
    contactItem.current.name.value.length === 0 ||
    contactItem.current.email.value.length === 0 ||
    contactItem.current.email.value.indexOf("@") === -1 ||
    contactItem.current.message.value.length < 10
      ? setErrorModal(true)
      : sendEmail(e);
  };
  const sendEmail = (e) => {
    e.preventDefault();
    setLoadingModal(true);
    emailjs
      .sendForm(
        "service_67uzu63",
        "template_p3o02dx",
        contactItem.current,
        "1h3cYgSO_1YQYhAyO"
      )
      .then(
        (result) => {
          setLoadingModal(false);
          setSubmitModal(true);
        },
        (error) => {
          setLoadingModal(false);
          alert(
            "에러가 발생했습니다. 이메일 또는 전화번호로 연락 부탁드립니다."
          );
          setErrorModal(true);
        }
      );
  };
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <form ref={contactItem} className="Input-Box">
        <div className="Input-Item">
          <div className="Input-Label">
            <span className="Contact-Input-Top">성함</span>
            <span>Name</span>
          </div>
          <input name="name" placeholder="필수 작성"></input>
        </div>
        <div className="Input-Item">
          <div className="Input-Label">
            <span className="Contact-Input-Top">직위</span>
            <span>Position</span>
          </div>
          <input
            name="position"
            placeholder="공란 가능 / Possible Empty"
          ></input>
        </div>
        <div className="Input-Item">
          <div className="Input-Label">
            <span className="Contact-Input-Top">업체명</span>
            <span>Company</span>
          </div>
          <input
            name="company"
            placeholder="공란 가능 / Possible Empty"
          ></input>
        </div>
        <div className="Input-Item">
          <div className="Input-Label">
            <span className="Contact-Input-Top">이메일</span>
            <span>Email</span>
          </div>
          <input type="email" name="email" placeholder="필수 작성"></input>
        </div>
        <div className="Input-Item">
          <div className="Input-Label">
            <span className="Contact-Input-Top">연락처</span>
            <span>Phone</span>
          </div>
          <input
            type="tel"
            name="phone"
            placeholder="공란 가능 / Possible Empty"
          ></input>
        </div>

        <textarea
          name="message"
          placeholder="문의하실 내용을 작성해주세요"
        ></textarea>
        <button onClick={Submit} type="submit" className="Submit">
          무료 상담 진행
          <p style={{ marginTop: 1 }}>Fee Free consulting</p>
        </button>
      </form>
      <div className="Safe-Area-Bottom"></div>
      {submitModal ? (
        <div
          style={{
            position: "absolute",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="ModalItem">
            <span className="ModalText" style={{ color: "black" }}>
              상담 신청 해주셔서 감사합니다.
            </span>
            <span className="ModalText" style={{ color: "black" }}>
              최대한 빠른 시간내에 답변드리겠습니다.
            </span>
            <span
              className="ModalText"
              style={{ color: "black", marginTop: 10, fontSize: 14 }}
            >
              Thank you for applying for a consultation
            </span>
            <span
              className="ModalText"
              style={{ color: "black", fontSize: 14 }}
            >
              I will reply as soon as possible.
            </span>
            <button
              type="submit"
              onClick={() => {
                setSubmitModal(false);
                window.location.reload(false);
              }}
              className="ModalClose"
            >
              close
            </button>
          </div>
        </div>
      ) : null}
      {errorModal ? (
        <div
          style={{
            position: "absolute",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="ModalItem">
            <span className="ErrorModalText" style={{ color: "black" }}>
              성함과 이메일, 메세지 10자 이상 작성해주셔야 합니다.
            </span>
            <span
              className="ErrorModalText"
              style={{ color: "black", fontSize: 14 }}
            >
              Please check your Name, Email and Message again
            </span>
            <button
              onClick={() => {
                setErrorModal(false);
              }}
              className="ModalClose"
            >
              close
            </button>
          </div>
        </div>
      ) : null}
      {loadingModal ? (
        <div
          style={{
            position: "absolute",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      ) : null}
    </div>
  );
};
export default ContactPage;
