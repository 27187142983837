import { useEffect, useState } from "react";
import "./PortfolioCss.css";
import InstagramVideo from "./InstagramVideo";

export const PortfolioPage = ({ screenState, logo }) => {
  const videoItems = [
    "https://www.youtube.com/embed/wpkH1eonsbg?controls=0",
    "https://www.youtube.com/embed/9lG3uxgKc00?controls=0",
    "https://www.youtube.com/embed/JgSuaB_xJXE?controls=0",
    "https://www.youtube.com/embed/BlTiAKpe2jc?controls=0",
    "https://www.youtube.com/embed/KWDrbpM8uBM?controls=0",
    "https://www.youtube.com/embed/5LLnDPB8rII?controls=0",
    "https://www.youtube.com/embed/eOg6MWZuU9s?controls=0",
    "https://www.youtube.com/embed/hALUCllhb60?controls=0",
    "https://www.youtube.com/embed/yY3z16UyWjw?controls=0",
  ];

  const assistantVideos = [
    "https://www.youtube.com/embed/tgSvrOz9A5I",
    "https://www.youtube.com/embed/rkqw03gXnXc",
    "https://www.youtube.com/embed/Wiq0J7_jHI8",
    "https://www.youtube.com/embed/UWoyeuwj9c0",
    "https://www.youtube.com/embed/jHf8EXC5rd8",
    "https://www.youtube.com/embed/LYJDf9oPQ-0",
    "https://www.instagram.com/p/CDfRiltpFhO/?utm_source=ig_embed&amp;utm_campaign=loading",
    "https://www.youtube.com/embed/cO9DbwTF7rY",
    "https://www.youtube.com/embed/jK265hGHzAo",
    "https://www.youtube.com/embed/rCWqBvFjs4I",
    "https://www.youtube.com/embed/HV2bh25n00s",
    "https://www.youtube.com/embed/CK_Zv5cJ-fk",
  ];
  const [iframeLoad, setIframeLoad] = useState(false);
  const iframeLoading = () => {
    setIframeLoad(true);
  };
  return (
    <div style={{ width: "100%", backgroundColor: "black" }}>
      <div className="Safe-Area"></div>
      <div className="Safe-Area2"></div>
      <div className="Title">
        <span className="subTitle">Director</span>
      </div>
      <div className="Grid-Container">
        {videoItems.map((item, index) => {
          return (
            <div key={`videoItem-${index}`}>
              {index % 2 === 0 ? (
                <div
                  className={
                    screenState === "web" ? "Grid-Column" : "Grid-Column-Mobile"
                  }
                >
                  <div className="Grid-Item-Space"></div>
                  <div className="Grid-Item">
                    <div className="Grid-Overflow">
                      {iframeLoad ? null : (
                        <span className="loader loadingPosition"></span>
                      )}
                      {videoItems[index].indexOf("youtube") === -1 ? (
                        <InstagramVideo link={item} />
                      ) : (
                        <iframe
                          width="100%"
                          height="100%"
                          src={videoItems[index]}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                          onLoad={() => {
                            iframeLoading();
                          }}
                        ></iframe>
                      )}
                    </div>
                  </div>
                  <div className="Grid-Item-Space"></div>
                  <div className="Grid-Item">
                    <div className="Grid-Overflow">
                      {iframeLoad ? null : (
                        <span className="loader loadingPosition"></span>
                      )}
                      {videoItems[index + 1] === undefined ? (
                        <img style={{ width: "100%" }} src={logo} />
                      ) : videoItems[index + 1].indexOf("youtube") === -1 ? (
                        <InstagramVideo link={item} />
                      ) : (
                        <iframe
                          width="100%"
                          height="100%"
                          src={videoItems[index + 1]}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                          onLoad={() => {
                            iframeLoading();
                          }}
                        ></iframe>
                      )}
                    </div>
                  </div>
                  <div className="Grid-Item-Space"></div>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
      <div className={screenState === "web" ? "Title" : "Title_Mobile"}>
        <span className="subTitle">Assistant Director / Producer</span>
      </div>
      <div className="Grid-Container">
        {assistantVideos.map((item, index) => {
          return (
            <div key={`assistantVideoItem-${index}`}>
              {index % 2 === 0 ? (
                <div
                  className={
                    screenState === "web" ? "Grid-Column" : "Grid-Column-Mobile"
                  }
                >
                  <div className="Grid-Item-Space"></div>
                  <div className="Grid-Item">
                    <div className="Grid-Overflow">
                      {iframeLoad ? null : (
                        <span className="loader loadingPosition"></span>
                      )}
                      {assistantVideos[index].indexOf("youtube") === -1 ? (
                        <InstagramVideo link={item} />
                      ) : (
                        <iframe
                          width="100%"
                          height="100%"
                          src={assistantVideos[index]}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                          onLoad={() => {
                            iframeLoading();
                          }}
                        ></iframe>
                      )}
                    </div>
                  </div>
                  <div className="Grid-Item-Space"></div>
                  <div className="Grid-Item">
                    <div className="Grid-Overflow">
                      {iframeLoad ? null : (
                        <span className="loader loadingPosition"></span>
                      )}
                      {assistantVideos[index + 1] === undefined ? (
                        <img style={{ width: "100%" }} src={logo} />
                      ) : assistantVideos[index + 1].indexOf("youtube") ===
                        -1 ? (
                        <InstagramVideo link={item} />
                      ) : (
                        <iframe
                          width="100%"
                          height="100%"
                          src={assistantVideos[index + 1]}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                          onLoad={() => {
                            iframeLoading();
                          }}
                        ></iframe>
                      )}
                    </div>
                  </div>
                  <div className="Grid-Item-Space"></div>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
      <div className="Safe-Area-Bottom"></div>
    </div>
  );
};
export default PortfolioPage;
