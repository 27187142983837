import workflow from "../static/images/workflow.png";
export const AboutPage = ({ screenState, logo }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          overflow: "scroll",
          width: "100%",
          height: "100%",
          display: "flex",
          backgroundColor: "black",
        }}
      >
        <div
          style={{
            marginTop: 120,
            marginBottom: 120,
            overflow: "scroll",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={
              screenState === "web"
                ? {
                    backgroundColor: "black",
                    width: "100%",
                    height: 140,
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 20,
                    marginBottom: 10,
                  }
                : {
                    width: "100%",
                    height: 80,
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 20,
                    marginBottom: 10,
                  }
            }
          >
            <img src={logo} style={{ height: "100%" }} />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                color: "white",
                width: 370,
              }}
            >
              <span
                className={
                  screenState === "web" ? "About_Text" : "About_Text_Mobile"
                }
              >
                사이아인은 클라이언트의 다양한 상황과 조건 그리고 목적에
                초인적인 집중력을 발휘하여 최고의 퀄리티를 만들겠다는 의지를
                담은 네이밍입니다.
              </span>
              <span
                className={
                  screenState === "web" ? "About_Text" : "About_Text_Mobile"
                }
              >
                라이브한 영상부터 시네마틱 영상까지 다양한 경험을 토대로 원활한
                커뮤니케이션의 경험을 안겨드립니다.
              </span>
              <span
                className={
                  screenState === "web" ? "About_Text" : "About_Text_Mobile"
                }
                style={{ letterSpacing: 1, wordSpacing: 2.2 }}
              >
                또한, 사이아인은 파트너와 함께 지속적이며 폭발적인 성장을 위해
                절대 만족을 최우선으로 하여 영상 제작 올인원 솔루션을 제공할
                것입니다.
              </span>
              <span
                className={
                  screenState === "web" ? "About_Text" : "About_Text_Mobile"
                }
              >
                Cyain is a naming that expresses the determination to create the
                highest quality by focusing superhumanly on the various
                situations, conditions, and purposes of the client.
              </span>
              <span
                className={
                  screenState === "web" ? "About_Text" : "About_Text_Mobile"
                }
              >
                Based on various experiences from live to cinematic videos, we
                provide a smooth communication experience.
              </span>
              <span
                className={
                  screenState === "web" ? "About_Text" : "About_Text_Mobile"
                }
                style={{ letterSpacing: 1, wordSpacing: 2.2 }}
              >
                In addition, Cyain will work with its partners to provide an
                all-in-one video production solution with absolute satisfaction
                as a top priority for continuous and explosive growth.
              </span>
            </div>
          </div>
          <div style={{ width: "80%", marginTop: 20 }}>
            <img
              src={workflow}
              style={{ width: "100%", objectFit: "contain" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutPage;
