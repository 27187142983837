import { useEffect, useState } from "react";
import "./App.css";
import "./WebPage/WebCommon.css";
import "./fonts.css";
import { debounce } from "lodash";
import MainPage from "./WebPage/MainPage";
import AboutPage from "./WebPage/AboutPage";
import PortfolioPage from "./WebPage/PortfolioPage";
import ContactPage from "./WebPage/ContactPage";
import ErrorPage from "./WebPage/ErrorPage";
import instaIcon from "./static/images/instaIcon.png";
import instaIconWhite from "./static/images/instaIconWhite.png";
import hamburgerIcon from "./static/images/hamburgerIcon.png";
import hamburgerIconWhite from "./static/images/hamburgerIcon-White.png";
import logo from "./static/images/cyainLogoWhite.png";
function App() {
  let scrollIndex = 0;
  // 가로 크기 변환시 가로 크기 측정
  const [windowWidthSize, setWindowWidthSize] = useState(window.innerWidth);
  const [screenState, setScreenState] = useState("web");
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const handleResize = debounce(() => {
    setWindowWidthSize(window.innerWidth);
  }, 1000);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    function ScreenSizeChange() {
      if (windowWidthSize > 884) {
        setScreenState("web");
      } else {
        setScreenState("mobile");
      }
      scrollIndex = 0;
    }
    ScreenSizeChange();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const [pageTransition, setPageTransition] = useState("Main");
  const [instaIconSelect, setInstaIconSelect] = useState(instaIconWhite);

  const [onTouchStartAbout, setOnTouchStartAbout] = useState(false);
  const [onTouchStartPortfolio, setOnTouchStartPortfolio] = useState(false);
  const [onTouchStartContact, setOnTouchStartContact] = useState(false);
  const [ani, setAni] = useState(false);
  return (
    <div
      className="App"
      style={{
        width: "100vw",
        height: "100vh",
        margin: 0,
        padding: 0,
        backgroundColor: "black",
      }}
    >
      <div className="Web" style={{ width: "100%", height: "100%" }}>
        <header>
          {screenState === "web" ? (
            <div className="Header">
              <img
                className="Nav_logo"
                src={logo}
                onClick={() => {
                  setPageTransition("Main");
                }}
              />
              <nav className="Nav-Box">
                <button
                  className="Nav_Button"
                  onClick={() => {
                    setPageTransition("About");
                  }}
                >
                  About
                </button>
                <button
                  className="Nav_Button"
                  onClick={() => {
                    setPageTransition("Portfolio");
                  }}
                >
                  Portfolio
                </button>
                <button
                  className="Nav_Button"
                  onClick={() => {
                    setPageTransition("Contact");
                  }}
                >
                  Contact
                </button>
                <button
                  className="Nav_Icon"
                  onClick={() => {
                    window.open("https://www.instagram.com/cyain.kr/");
                  }}
                  onMouseEnter={() => {
                    setInstaIconSelect(instaIcon);
                  }}
                  onMouseOut={() => {
                    setInstaIconSelect(instaIconWhite);
                  }}
                >
                  <img className="Nav_Icon_Image" src={instaIconSelect} />
                </button>
                {/* <button className="Nav_Icon">
                  <img className="Nav_Icon_Image" src={vimeoIcon} />
                </button> */}
              </nav>
            </div>
          ) : screenState === "mobile" ? (
            <div className="Header">
              <img
                className="Nav_logo"
                src={logo}
                onClick={() => {
                  setPageTransition("Main");
                }}
              />

              <nav className="Nav-Box-Mobile">
                <button
                  className="Nav_Button_Mobile"
                  onClick={() => {
                    setMobileMenuOpen(!mobileMenuOpen);
                  }}
                >
                  <img className="Nav_logo_mobile" src={hamburgerIcon} />
                </button>
              </nav>
            </div>
          ) : (
            alert("error")
          )}
        </header>
        {mobileMenuOpen ? (
          <div className="Mobile-Menu">
            <div
              className="Mobile-Menu-Empty"
              onTouchEnd={() => {
                setMobileMenuOpen(false);
              }}
            ></div>
            <div className="Mobile-Menu-Item">
              <div
                className="Mobile-Menu-Close"
                onTouchEnd={() => {
                  setMobileMenuOpen(false);
                }}
              >
                <span>close</span>
              </div>
              <div className="Mobile-Menu-Position">
                <button
                  className="Mobile-Nav"
                  onTouchStart={() => {
                    setOnTouchStartAbout(true);
                  }}
                  onTouchEnd={() => {
                    setOnTouchStartAbout(false);
                    setPageTransition("About");
                    setMobileMenuOpen(false);
                  }}
                >
                  <span
                    className={
                      onTouchStartAbout
                        ? "Mobile-Nav-Text-Touch"
                        : "Mobile-Nav-Text"
                    }
                  >
                    About
                  </span>
                </button>
                <div
                  className="Mobile-Nav"
                  onTouchStart={() => {
                    setOnTouchStartPortfolio(true);
                  }}
                  onTouchEnd={() => {
                    setOnTouchStartPortfolio(false);
                    setPageTransition("Portfolio");
                    setMobileMenuOpen(false);
                  }}
                >
                  <span
                    className={
                      onTouchStartPortfolio
                        ? "Mobile-Nav-Text-Touch"
                        : "Mobile-Nav-Text"
                    }
                  >
                    Portfolio
                  </span>
                </div>
                <div
                  className="Mobile-Nav"
                  onTouchStart={() => {
                    setOnTouchStartContact(true);
                  }}
                  onTouchEnd={() => {
                    setOnTouchStartContact(false);
                    setPageTransition("Contact");
                    setMobileMenuOpen(false);
                  }}
                >
                  <span
                    className={
                      onTouchStartContact
                        ? "Mobile-Nav-Text-Touch"
                        : "Mobile-Nav-Text"
                    }
                  >
                    Contact
                  </span>
                </div>
                <div className="Mobile-Nav">
                  <button
                    className="Nav_Icon_Mobile"
                    onTouchStart={() => {
                      setInstaIconSelect(instaIcon);
                    }}
                    onTouchEnd={() => {
                      setInstaIconSelect(instaIconWhite);
                      window.open("https://www.instagram.com/cyain.kr/");
                    }}
                  >
                    <img className="Nav_Icon_Mobile" src={instaIconSelect} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {pageTransition === "Main" ? (
          <MainPage scrollIndex={scrollIndex} />
        ) : pageTransition === "About" ? (
          <AboutPage screenState={screenState} logo={logo} />
        ) : pageTransition === "Portfolio" ? (
          <PortfolioPage screenState={screenState} logo={logo} />
        ) : pageTransition === "Contact" ? (
          <ContactPage />
        ) : (
          <ErrorPage />
        )}
        <footer style={screenState === "web" ? null : { height: 100 }}>
          <img
            className={
              screenState === "web" ? "Footer_Logo" : "Footer_Logo_Mobile"
            }
            src={logo}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: 40,
              marginTop: 0,
            }}
          >
            <div style={{ marginBottom: "2px" }}>
              <span
                className={
                  screenState === "web"
                    ? "Footer_Contact_Class"
                    : "Footer_Contact_Class_Mobile"
                }
              >
                Director.
              </span>
              <span
                className={
                  screenState === "web"
                    ? "Footer_Contact_Name"
                    : "Footer_Contact_Name_Mobile"
                }
              >
                Cho Sung-ik
              </span>
            </div>
            <span
              className={
                screenState === "web"
                  ? "Footer_Contact_Phone"
                  : "Footer_Contact_Phone_Mobile"
              }
            >
              Phone : 010-4742-5113
            </span>
            <span
              className={
                screenState === "web"
                  ? "Footer_Contact_Email"
                  : "Footer_Contact_Email_Mobile"
              }
            >
              Email : chosungik@cyain.kr
            </span>
            <span
              className={
                screenState === "web" ? "Footer_Slogun" : "Footer_Slogun_Mobile"
              }
            >
              클라이언트의 니즈에 초인적인 집중을
            </span>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
